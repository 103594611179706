<template>
  <div>
    <list
      ref="refPartsList"
      sort-by="PedimentoId"
      refetch-records-name="operacionesDocumentoFaltante"
      key-field="PedimentoId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchMissingPedimentos"
    >
    </list>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import mbgeService from '@/services/mbge.service'
import List from '@/components/List.vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    List,
  },
  setup() {
    const refPartsList = ref(null)
    const {
      fetchMissingPedimentos,
    } = mbgeService()

    const filters = ref([
    ])

    const tableColumns = [
      {
        key: 'aduana',
        label: 'Aduana',
        sortable: true,
        sortKey: 'Aduana',
      },
      {
        key: 'patente',
        label: 'Patente',
        sortable: true,
        sortKey: 'Patente',
      },
      {
        key: 'claveDocumento',
        label: 'ClaveDocumento',
        sortable: true,
        sortKey: 'DocumentoClave',
      },
      {
        key: 'numeroPedimento',
        label: 'NumeroPedimento',
        sortable: true,
        sortKey: 'PedimentoNumero',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]

    const tableActions = ref([
      {
        name: 'loadFile',
        label: 'Cargar documento',
        routeName: 'apps-transfers-mbge-pedimento-load-document',
        params: ['pedimentoId'],
        icon: 'PlusCircleIcon',
      },
    ])

    const actions = ref([
    ])

    return {
      refPartsList,

      actions,
      filters,
      tableActions,
      tableColumns,

      fetchMissingPedimentos,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
