import defaultService from './_default.service'

export default function mbgeService() {
  const defaultMethods = defaultService('MITRADE')
  const fetchMissingPedimentos = (data, callback) => defaultMethods.fetch('OperacionesDocumentoFaltante', {}, data, callback)
  const createPedimentoDocument = (data, callback) => defaultMethods.create('CargaOperacionDocumento', { }, true, data, callback)

  return {
    fetchMissingPedimentos,
    createPedimentoDocument,
  }
}
